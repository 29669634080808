import { useCallback, useEffect, useState } from 'react'

export function useWindowLocationHash(): { hash: string; updateHash: (hash: string) => void } {
  const [hash, setHash] = useState(decode(window.location.hash))

  const handleHashChange = useCallback((e: HashChangeEvent) => {
    const url = new URL(e.newURL)
    setHash(decode(url.hash))
  }, [])

  const updateHash = useCallback((newHash: string) => {
    window.location.hash = encode(newHash)
  }, [])

  useEffect(() => {
    window.addEventListener('hashchange', handleHashChange)

    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [handleHashChange])

  return {
    hash,
    updateHash,
  }
}

function decode(hash: string): string {
  return decodeURIComponent(ensureSharpRemoved(hash))
}

function encode(hash: string): string {
  return encodeURIComponent(ensureSharpRemoved(hash))
}

function ensureSharpRemoved(hash: string): string {
  if (hash.startsWith('#')) {
    return hash.substring(1)
  }

  return hash
}
