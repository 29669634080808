import { useCallback, useEffect, useState } from 'react'
import { useWindowLocationHash } from './use-window-location-hash.js'

/**
 *
 * @param dialogId A unique identifier to identify the relevant dialog and avoid dialog confusion.
 */
export function useDialogVisibility(dialogId: string): { visible: boolean; show: () => void; hide: () => void } {
  const dialogHash = `dialog-${dialogId}`
  const { hash, updateHash } = useWindowLocationHash()

  const [visible, setVisible] = useState(hash === dialogHash)

  const show = useCallback(() => {
    updateHash(dialogHash)
  }, [dialogHash, updateHash])

  const hide = useCallback(() => {
    updateHash('')
  }, [updateHash])

  useEffect(() => {
    setVisible(hash === dialogHash)
  }, [hash, dialogHash])

  return { visible, show, hide }
}
